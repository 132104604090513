import { Component, Input } from '@angular/core';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  @Input()
  profile: StreApperInfo;

  @Input()
  streappers: StreApperInfo[] = [];
}
