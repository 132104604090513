<div
  class="desktop-width-limiter page-padding info-page sebbies"
  *ngIf="streappers.length > 0"
>
  <header-content [profile]="profile" [rank]="rank"></header-content>
  <user-list [profile]="profile" [streappers]="streappers"></user-list>
</div>
<ng-container *ngIf="streappers.length === 0">
  <div class="loading">
    <mat-progress-spinner
      class="loading-spinner"
      [diameter]="250"
      mode="indeterminate"
      color="primary"
    >
    </mat-progress-spinner>
    <div class="loading-text"><h1>Laden...</h1></div>
  </div>
</ng-container>
