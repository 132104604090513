import { Component, Input } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ContactDialogComponent } from 'src/app/components/contact-dialog/contact-dialog.component';
import { StreApperInfo } from 'src/app/models/streapper-info.model';

@Component({
  selector: 'header-content',
  templateUrl: './header-content.component.html',
  styleUrl: './header-content.component.scss',
})
export class HeaderContentComponent {
  @Input()
  profile: StreApperInfo;

  @Input()
  rank: number;

  public constructor(private readonly legacyDialog: MatLegacyDialog) {}

  openContactDialog(): void {
    this.legacyDialog.open<ContactDialogComponent>(ContactDialogComponent, {
      autoFocus: false, // To prevent select state of close button in header
    });
  }
}
