<div class="desktop-width-limiter page-padding detail-page">
  <ng-container *ngIf="!loading && !error && profile">
    <div class="page-content">
      <banner [profile]="profile" [userType]="1" />
      <div class="info">
        <div class="top-info">
          <profile-userinfo-field
            class="user-info col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4"
            [userInfo]="userInfo"
          />
          <profile-location-field
            class="location-info col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4"
          />
          <div class="widgets col-12 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4">
            <div class="top row">
              <profile-widget
                [iconName]="'drafts'"
                [text]="'profile.widgets.contact_admin' | translate"
                [backgroundType]="'white'"
                (click)="contactAdmin()"
                style="padding-right: 5px"
                class="col-6"
              />
              <profile-widget
                [iconName]="'logout'"
                [text]="'profile.widgets.logout' | translate"
                [backgroundType]="'grey'"
                (click)="logout()"
                style="padding-left: 5px"
                class="col-6"
              />
            </div>
            <div class="bottom row">
              <profile-widget
                [iconName]="'share'"
                [text]="'profile.widgets.share' | translate"
                [backgroundType]="'white'"
                (click)="share()"
                style="padding-right: 5px"
                class="col-6"
              />
              <profile-widget
                [iconName]="'person_remove'"
                [text]="'profile.widgets.delete' | translate"
                [backgroundType]="'transparent'"
                (click)="deleteAccount()"
                style="padding-left: 5px"
                class="col-6"
              />
            </div>
          </div>
        </div>
        <div class="bottom-info">
          <about-me-field
            style="height: inherit"
            class="about-me col-12 col-lg-8 col-xl-8 col-xxl-8 col-xxxl-8"
            [description]="profile.profile"
            [userInfo]="userInfo"
          />
          <div class="counters col-12 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-4">
            <wide-counter-button
              [count]="profile.amountOfStreApps"
              [text]="'profile.streapps_posted' | translate"
            ></wide-counter-button>
            <sebbie-counter
              [monthlySebbiePoints]="profile.monthlySebbiePoints"
              [sebbiePoints]="profile.sebbiePoints"
            ></sebbie-counter>
            <div
              class="counter-padding sebbie-rank"
              (click)="navigateToSebbiePage()"
              [title]="'profile.widgets.sebbie_rang' | translate"
            >
              <div class="counter">
                <img
                  class="medal-image"
                  src="assets/images/sebbies/ranking-star-solid_white.svg"
                  alt="Sebbies"
                />
                <span class="text">{{
                  "profile.widgets.sebbie_rang" | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <my-streapps />
    </div>
  </ng-container>
</div>
