import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SebbieOverviewComponent } from './sebbie-overview/sebbie-overview.component';
import { UserListComponent } from './_components/user-list/user-list.component';
import { UserCardComponent } from './_components/user-card/user-card.component';
import { HeaderContentComponent } from './_components/header-content/header-content.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  declarations: [
    SebbieOverviewComponent,
    UserListComponent,
    UserCardComponent,
    HeaderContentComponent,
  ],
  imports: [CommonModule, ComponentsModule],
  exports: [SebbieOverviewComponent],
})
export class SebbiesModule {}
