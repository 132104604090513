<mat-card class="widget">
  <div class="medal-content" slot="content">
    <div class="title">
      <img
        *ngIf="!ownProfile"
        class="medal-image"
        src="assets/images/sebbies/ranking-star-solid_blue.svg"
        alt="Sebbies"
      />
      <img
        *ngIf="ownProfile"
        class="medal-image"
        src="assets/images/sebbies/ranking-star-solid.svg"
        alt="Sebbies"
      />
      <span [class.own-profile]="ownProfile">{{
        streapper.sebbiePoints === 0 ? "-" : rank
      }}</span>
    </div>

    <div class="attribute cursor-pointer image-section">
      <img
        class="profile-image"
        alt="Profielafbeelding"
        [src]="getProfilePicture()"
        onerror="this.onerror=null;this.src='/assets/images/user.png';"
        height="35"
        width="35"
      />
      <div class="name-and-reporter">
        <span class="category">{{ streapper.name || streapper.userName }}</span>
        <div class="reporter-title">Reporter</div>
      </div>
    </div>

    <div class="sebbie-image">
      <img
        src="assets/images/sebbies/Coins-5.png"
        height="60px"
        width="165px"
        alt="Sebbies"
      />
      <span>{{ streapper.sebbiePoints }}</span>
    </div>
  </div>
</mat-card>
