import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewListComponent } from './modules/overview/overview-list/overview-list.component';
import { DetailPageComponent } from './modules/detail/detail-page/detail-page.component';
import { NewEventFormComponent } from './modules/create-event/new-event-form/new-event-form.component';
import { AuthCallbackComponent } from './modules/auth/auth-callback/auth-callback.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { ProfileComponent } from './modules/profile/profile/profile.component';
import { AuthGuard } from './guards/auth.guard';
import { MyStreAppsComponent } from './modules/profile/_components/my-streapps/my-streapps.component';
import { SilentRefreshComponent } from './modules/auth/silent-refresh/silent-refresh.component';
import { ReportersComponent } from './modules/reporter/reporters/reporters.component';
import { ReporterComponent } from './modules/reporter/reporter/reporter.component';
import { AdsOverviewComponent } from './modules/ads/ads-overview/ads-overview.component';
import { AccessDeniedComponent } from './modules/auth/access-denied/access-denied.component';
import { CreateAdComponent } from './modules/ads/create-ad/create-ad.component';
import { PromoOverviewComponent } from './modules/ads/promo-overview/promo-overview.component';
import { PromoDetailComponent } from './modules/ads/promo-detail/promo-detail.component';
import { VideoComponent } from './modules/video/video.component';
import { OverviewMapComponent } from './modules/overview/overview-map/overview-map.component';
import { ProfileSettingsComponent } from './modules/profile/profile-settings/profile-settings.component';
import { Overview112Component } from './modules/overview/overview-112/overview-112.component';
import { P2000DetailPageComponent } from './modules/detail/p2000-detail-page/p2000-detail-page.component';
import { Four04Component } from './modules/statuscodes/four04/four04.component';
import { AddContentFormComponent } from './modules/create-event/add-content-form/add-content-form.component';
import { UserComponent } from './modules/reporter/user/user.component';
import { EditContentFormComponent } from './modules/create-event/edit-content-form/edit-content-form.component';
import { ProfileReporterComponent } from './modules/profile/profile-reporter/profile-reporter.component';
import { InfoComponent } from './modules/info/info.component';
import { SebbieOverviewComponent } from './modules/sebbies/sebbie-overview/sebbie-overview.component';

const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
    data: { hidelayout: true },
  },
  { path: 'silent-refresh.html', component: SilentRefreshComponent },
  { path: 'login', component: LoginComponent, data: { hidelayout: true } },
  { path: 'video', component: VideoComponent, data: { hidelayout: true } },
  {
    path: '',
    component: OverviewListComponent,
  },
  {
    path: 'kaart',
    component: OverviewMapComponent,
  },
  {
    path: 'lokaal',
    component: OverviewListComponent,
  },
  {
    path: '112',
    component: Overview112Component,
  },
  {
    path: 'detail/:id',
    component: DetailPageComponent,
  },
  {
    path: '112/detail',
    component: P2000DetailPageComponent,
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
  },
  {
    path: 'profile-reporter',
    canActivate: [AuthGuard],
    component: ProfileReporterComponent,
  },
  {
    path: 'profile-settings',
    canActivate: [AuthGuard],
    component: ProfileSettingsComponent,
  },
  {
    path: 'my',
    canActivate: [AuthGuard],
    component: MyStreAppsComponent,
  },
  {
    path: 'create-event',
    canActivate: [AuthGuard],
    component: NewEventFormComponent,
  },
  {
    path: 'add-content/:id',
    canActivate: [AuthGuard],
    component: AddContentFormComponent,
  },
  {
    path: 'edit-content/:id',
    canActivate: [AuthGuard],
    component: EditContentFormComponent,
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  {
    path: 'over-ons',
    redirectTo: 'info',
  },
  {
    path: 'terms',
    redirectTo: 'info',
  },
  {
    path: 'contact',
    redirectTo: 'info',
  },
  {
    path: 'reporters',
    component: ReportersComponent,
  },
  {
    path: 'reporters/detail',
    component: ReporterComponent,
  },
  {
    path: 'users/detail',
    component: UserComponent,
  },
  {
    path: 'ads',
    canActivate: [AuthGuard],
    data: { roles: ['Ads'] },
    component: AdsOverviewComponent,
  },
  {
    path: 'promos',
    canActivate: [AuthGuard],
    data: { roles: ['Ads'] },
    component: PromoOverviewComponent,
  },
  {
    path: 'create-ad',
    canActivate: [AuthGuard],
    data: { roles: ['Ads'] },
    component: CreateAdComponent,
  },
  {
    path: 'promo/:id',
    component: PromoDetailComponent,
  },
  {
    path: 'promo/:id/:type',
    component: PromoDetailComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'ranglijst',
    component: SebbieOverviewComponent,
  },
  { path: '**', component: Four04Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
