import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StreApperInfo } from 'src/app/models/streapper-info.model';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss',
})
export class UserCardComponent {
  @Input()
  streapper: StreApperInfo;

  @Input()
  ownProfile: boolean = false;

  @Input()
  rank: number;

  public constructor(private domSanitizer: DomSanitizer) {}

  getProfilePicture() {
    if (this.streapper.picture) {
      if (this.streapper.picture?.startsWith('http')) {
        return this.streapper.picture;
      }

      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.streapper.picture
      );
    }
  }
}
