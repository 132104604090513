<div class="desktop-width-limiter overview-page">
  <div class="row overview-items">
    <div
      class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxxl-2 overview-item"
      *ngFor="let streapper of streappers; let i = index"
    >
      <user-card
        [streapper]="streapper"
        [ownProfile]="profile?.id === streapper.id"
        [rank]="i + 1"
      ></user-card>
    </div>
  </div>
</div>
