<div
  class="desktop-width-limiter overview-page"
  *ngIf="profile && profile.isReporter"
>
  <div class="row overview-items">
    <div class="col-xs-12 col-md-6 col-xl-4 overview-item">
      <mat-card class="widget">
        <div class="title" slot="header">Jouw rang</div>
        <div class="medal-content" slot="content">
          <img
            class="medal-image"
            src="assets/images/sebbies/medal-solid.svg"
            alt="Sebbies"
          />
          <span>{{ profile.sebbiePoints === 0 ? "-" : rank }}</span>
        </div>
      </mat-card>
    </div>
    <div class="col-xs-12 col-md-6 col-xl-4 overview-item">
      <mat-card class="widget">
        <div class="title" slot="header">Nieuw deze maand</div>
        <div class="sebbies-content" slot="content">
          <img
            src="assets/images/sebbies/Coins-3.png"
            height="126px"
            width="228px"
            alt="Sebbies"
          />
          <span>+ {{ profile.monthlySebbiePoints }}</span>
        </div>
      </mat-card>
    </div>
    <div class="col-xs-12 col-md-6 col-xl-4 overview-item">
      <mat-card class="widget">
        <div class="title" slot="header">Totaal</div>
        <div class="sebbies-content" slot="content">
          <img
            src="assets/images/sebbies/Coins-5.png"
            height="120px"
            width="330px"
            alt="Sebbies"
          />
          <span>{{ profile.sebbiePoints }}</span>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div
  class="desktop-width-limiter overview-page"
  *ngIf="!profile || !profile.isReporter"
>
  <mat-card class="widget text-widget">
    <div class="text-content" slot="content">
      <span>
        Deze functionaliteit is alleen beschikbaar voor StreApp Reporters.<br /><br />
        Wil je ook reporter worden? Neem dan contact met ons op via het
        <strong (click)="openContactDialog()">contactformulier</strong></span
      >
    </div>
  </mat-card>
</div>
