import { Component, OnInit } from '@angular/core';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'sebbie-overview',
  templateUrl: './sebbie-overview.component.html',
  styleUrl: './sebbie-overview.component.scss',
})
export class SebbieOverviewComponent implements OnInit {
  profile: StreApperInfo;
  streappers: StreApperInfo[] = [];
  rank: number;

  constructor(
    private readonly authService: AuthService,
    private readonly streappService: StreappEventsService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.authService.isAuthenticated()) {
      this.profile = await this.streappService.getStreapperInfoId(
        this.authService.userId
      );
    }

    this.streappService.getStreAppReporters().then((reporters) => {
      // Sort reporters based on sebbiePoints property
      this.streappers = reporters.sort((a, b) => {
        return b.sebbiePoints - a.sebbiePoints;
      });

      this.rank =
        this.streappers.findIndex((x) => x.id === this.profile?.id) + 1;
    });
  }
}
